import { useState, useCallback, useMemo } from "react"
import { useCore } from "./useCore"
import { useApp } from "./useApp"
import { useShopify } from "./useShopify"
import { useQueries } from "./useQueries"
import { useCheckout, useCheckoutContext } from "./useCheckout"
import { useAnalytics } from "./useAnalytics"
import { useEmarsys } from "./useEmarsys"
import { CheckoutProcessor, FREE_GIFT_ATTR } from "../utils/checkoutProcessor"
import { useCustomerContext } from "./useCustomer"

export const useCart = () => {
  const {
    helpers: { storage, decodeShopifyId },
  } = useCore()
  const {
    mutations: { CHECKOUT_LINE_ITEMS_REPLACE, CHECKOUT_LINE_ITEM_ADD, CHECKOUT_LINE_ITEMS_REMOVE },
  } = useQueries()
  const {
    config: {
      settings: { keys },
    },
    globalStateReducer,
  } = useApp()
  const { customer } = useCustomerContext()
  const { checkout, livePromotionRules, freeGiftSets, setFreeGiftSets } = useCheckoutContext()
  const { setCheckout } = useCheckout()
  const { useMutation, checkoutNormaliser } = useShopify()
  const { trackCartUpdate } = useAnalytics()
  const { trackPage } = useEmarsys()
  const [loading, setLoading] = useState(false)
  const [loadingRemove, setLoadingRemove] = useState(false)
  const [loadingAttributes, setLoadingAttributes] = useState(false)
  const [errors, setErrors] = useState([])
  const checkoutId = checkout?.id || storage.get(keys?.checkout)
  const [, dispatch] = globalStateReducer

  const [lineItemsReplace] = useMutation(CHECKOUT_LINE_ITEMS_REPLACE)
  const [lineItemAdd] = useMutation(CHECKOUT_LINE_ITEM_ADD)
  const [lineItemsRemove] = useMutation(CHECKOUT_LINE_ITEMS_REMOVE)

  const availableFreeGiftSets = useMemo(() => {
    const addedFreeGiftsTally = {} // promotionRuleKey => count
    const checkoutLineItems = checkout ? checkout.lineItems : []
    for (const checkoutLineItem of checkoutLineItems) {
      for (const customAttribute of checkoutLineItem.customAttributes) {
        if (customAttribute.key === FREE_GIFT_ATTR) {
          addedFreeGiftsTally[customAttribute.value] = addedFreeGiftsTally[customAttribute.value] || 0
          addedFreeGiftsTally[customAttribute.value] += checkoutLineItem.quantity
        }
      }
    }
    const available = []
    for (const freeGiftSet of freeGiftSets) {
      if (
        typeof addedFreeGiftsTally[freeGiftSet.promotionActionKey] === "undefined" ||
        addedFreeGiftsTally[freeGiftSet.promotionActionKey] < freeGiftSet.takeCount
      ) {
        available.push(freeGiftSet)
      }
    }
    return available
  }, [freeGiftSets, checkout])

  /**
   * A list of products in availableFreeGiftSets, excluding those that are
   * already included in the cart
   */
  const availableFreeGiftProducts = useMemo(() => {
    let freeGiftProducts = []
    for (const freeGiftSet of availableFreeGiftSets) {
      freeGiftProducts = freeGiftProducts.concat(freeGiftSet.fromProducts)
    }
    return freeGiftProducts
    // Disabling the filtering as we already count the number of gifts available
    // a GWP product should be able to be added multiple times if mutltiple promotions are available

    // return freeGiftProducts.filter((product) => {
    //   const shopifyProduct = JSON.parse(product.shopify.shopifyRaw)
    //   const checkoutLineItems = checkout.lineItems || []
    //   return shopifyProduct.variants.every(variant => checkoutLineItems.every(lineItem => lineItem.variant.id !== variant.id))
    // })
  }, [availableFreeGiftSets])

  const addToCart = useCallback(
    async (product, variant, quantity = 1, customAttributes = []) => {
      setLoading(true)

      const processor = new CheckoutProcessor(checkout, livePromotionRules)
      processor.addLineItem(product, variant, quantity, customAttributes)
      const { lineItemInput: newLineItems, freeGiftSets: newFreeGiftSets } = processor.finaliseLineItemInput()

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          countryCode: storage.get(keys?.country),
          checkoutId,
          lineItems: newLineItems,
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) {
        setFreeGiftSets(newFreeGiftSets)
        setCheckout(data?.checkout)
        dispatch({
          type: "SHOW_CART",
        })
      }
      setLoading(false)

      const normalisedCheckout = checkoutNormaliser(data?.checkout)

      trackCartUpdate("add", variant.id, quantity, normalisedCheckout?.lineItems)
      trackPage(customer, normalisedCheckout)
    },
    [
      checkout,
      livePromotionRules,
      lineItemsReplace,
      storage,
      keys?.country,
      checkoutId,
      checkoutNormaliser,
      trackCartUpdate,
      trackPage,
      customer,
      setFreeGiftSets,
      setCheckout,
      dispatch,
    ]
  )

  const addFreeGiftToCart = useCallback(
    selectedVariant => {
      // Find the corresponding freeGiftSet which the variant belongs to
      let freeGiftSet = null
      let shopifyProduct = null
      loop: {
        for (const set of availableFreeGiftSets) {
          for (const product of set.fromProducts) {
            const parsedShopifyProduct = JSON.parse(product.shopify.shopifyRaw)
            for (const variant of parsedShopifyProduct.variants) {
              if (
                decodeShopifyId(variant.id, "ProductVariant")?.replace("gid://shopify/ProductVariant/", "") ===
                selectedVariant.id?.replace("gid://shopify/ProductVariant/", "")
              ) {
                freeGiftSet = set
                shopifyProduct = parsedShopifyProduct
                break loop
              }
            }
          }
        }
      }
      if (!freeGiftSet || !shopifyProduct) {
        return
      }
      console.log("checker: ", shopifyProduct, selectedVariant)
      // Add gift to cart
      return addToCart(shopifyProduct, selectedVariant, 1, [{ key: FREE_GIFT_ATTR, value: freeGiftSet.promotionActionKey }])
    },
    [availableFreeGiftSets, addToCart]
  )

  const addGiftCardToCart = useCallback(
    async (variantId, quantity = 1, customAttributes = []) => {
      setLoading(true)

      const {
        data: { checkoutLineItemsAdd: data, userErrors: errors },
      } = await lineItemAdd({
        variables: {
          checkoutId,
          lineItems: [{ quantity, variantId, customAttributes }],
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(data?.checkout)
      setLoading(false)

      const normalisedCheckout = checkoutNormaliser(data?.checkout)

      trackCartUpdate("add", variantId, quantity, normalisedCheckout?.lineItems)
      trackPage(customer, normalisedCheckout)
    },
    [lineItemAdd, checkoutId, setCheckout, checkoutNormaliser, trackCartUpdate, trackPage, customer]
  )

  const removeFromCart = useCallback(
    async lineItemId => {
      setLoadingRemove(true)

      const lineItemToRemove = checkout?.lineItems.find(({ id }) => id === lineItemId)
      if (lineItemToRemove) {
        const quantity = lineItemToRemove.quantity
        const processor = new CheckoutProcessor(checkout, livePromotionRules)
        processor.removeLineItem(lineItemToRemove.id)
        const { lineItemInput: newLineItems, freeGiftSets: newFreeGiftSets } = processor.finaliseLineItemInput()
        trackCartUpdate("remove", lineItemToRemove.variant.id, quantity, checkout?.lineItems)

        const {
          data: { checkoutLineItemsReplace: data, userErrors: errors },
        } = await lineItemsReplace({
          variables: {
            checkoutId,
            lineItems: newLineItems,
            countryCode: storage.get(keys?.country),
          },
        })

        if (errors?.length) setErrors(errors)
        if (data) {
          setFreeGiftSets(newFreeGiftSets)
          await setCheckout(data?.checkout)
          trackPage(customer, checkoutNormaliser(data?.checkout))
        }
      }

      setLoadingRemove(false)
    },
    [
      checkout,
      livePromotionRules,
      trackCartUpdate,
      lineItemsReplace,
      checkoutId,
      storage,
      keys?.country,
      setFreeGiftSets,
      setCheckout,
      trackPage,
      customer,
      checkoutNormaliser,
    ]
  )

  const updateQuantity = useCallback(
    async (lineItemId, quantity) => {
      setLoading(true)
      const processor = new CheckoutProcessor(checkout, livePromotionRules)
      processor.updateLineItemQuantity(lineItemId, quantity)
      const { lineItemInput: newLineItems, freeGiftSets: newFreeGiftSets } = processor.finaliseLineItemInput()

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems: newLineItems,
          countryCode: storage.get(keys?.country),
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) {
        setFreeGiftSets(newFreeGiftSets)
        setCheckout(data?.checkout)
      }
      setLoading(false)

      const normalisedCheckout = checkoutNormaliser(data?.checkout)

      trackPage(customer, normalisedCheckout)
    },
    [
      checkout,
      livePromotionRules,
      lineItemsReplace,
      checkoutId,
      storage,
      keys?.country,
      checkoutNormaliser,
      trackPage,
      customer,
      setFreeGiftSets,
      setCheckout,
    ]
  )

  const updateVariant = useCallback(
    async (prevVariantId, variantId) => {
      setLoading(true)
      const lineItems = checkout?.lineItems.map(lineItem => ({
        ...(lineItem.customAttributes && {
          customAttributes: lineItem.customAttributes.map(({ key, value }) => ({ key, value })),
        }),
        quantity: lineItem.quantity,
        variantId: lineItem.variant.id === prevVariantId ? variantId : lineItem.variant.id,
      }))

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
          countryCode: storage.get(keys?.country),
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(data?.checkout)
      setLoading(false)
    },
    [checkout?.lineItems, lineItemsReplace, checkoutId, storage, keys?.country, setCheckout]
  )

  const updateAttributes = useCallback(
    async (variantId, customAttributes) => {
      setLoadingAttributes(true)
      const lineItems = checkout?.lineItems?.map(lineItem =>
        lineItem.variant.id === variantId
          ? {
              customAttributes: [
                ...new Map(
                  [
                    ...lineItem?.customAttributes?.map(({ key, value }) => ({
                      key,
                      value,
                    })),
                    ...Object.entries(customAttributes)?.map(attr => ({
                      key: attr[0],
                      value: attr[1],
                    })),
                  ].map(item => [item?.key, item])
                ).values(),
              ],
              variantId,
              quantity: lineItem.quantity,
            }
          : {
              ...(lineItem?.customAttributes && {
                customAttributes: lineItem.customAttributes.map(({ key, value }) => ({
                  key,
                  value,
                })),
              }),
              quantity: lineItem.quantity,
              variantId: lineItem.variant.id,
            }
      )

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
          countryCode: storage.get(keys?.country),
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(data?.checkout)
      setLoadingAttributes(false)
    },
    [checkout?.lineItems, lineItemsReplace, checkoutId, storage, keys?.country, setCheckout]
  )

  const updateItem = useCallback(
    async (variantId, quantity, customAttributes) => {
      setLoading(true)
      const lineItems = checkout?.lineItems?.map(lineItem =>
        lineItem.variant.id === variantId
          ? {
              customAttributes: [
                ...new Map(
                  [
                    ...lineItem?.customAttributes?.map(({ key, value }) => ({
                      key,
                      value,
                    })),
                    ...Object.entries(customAttributes)?.map(attr => ({
                      key: attr[0],
                      value: attr[1],
                    })),
                  ].map(item => [item?.key, item])
                ).values(),
              ],
              variantId,
              quantity,
            }
          : {
              ...(lineItem?.customAttributes && {
                customAttributes: lineItem.customAttributes.map(({ key, value }) => ({
                  key,
                  value,
                })),
              }),
              quantity: lineItem.quantity,
              variantId: lineItem.variant.id,
            }
      )

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          lineItems,
          countryCode: storage.get(keys?.country),
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) setCheckout(data?.checkout)
      setLoading(false)
    },
    [checkout?.lineItems, lineItemsReplace, checkoutId, storage, keys?.country, setCheckout]
  )

  const clearCart = useCallback(async () => {
    setLoading(true)

    if (checkout?.lineItems?.length > 0) {
      checkout.lineItems.map(({ variant, quantity }) => trackCartUpdate("remove", variant?.id, quantity, checkout?.lineItems))
    }

    const {
      data: { checkoutLineItemsReplace: data, userErrors: errors },
    } = await lineItemsReplace({
      variables: {
        checkoutId,
        lineItems: [],
        countryCode: storage.get(keys?.country),
      },
    })

    if (errors?.length) setErrors(errors)
    if (data) {
      setCheckout(data?.checkout)
      trackPage(customer, checkoutNormaliser(data?.checkout))
    }
    setLoading(false)
  }, [
    checkout.lineItems,
    lineItemsReplace,
    checkoutId,
    storage,
    keys?.country,
    trackCartUpdate,
    setCheckout,
    trackPage,
    customer,
    checkoutNormaliser,
  ])

  return {
    addGiftCardToCart,
    addToCart,
    removeFromCart,
    updateQuantity,
    updateVariant,
    updateAttributes,
    loadingAttributes,
    addFreeGiftToCart,
    availableFreeGiftProducts,
    updateItem,
    clearCart,
    loading,
    loadingRemove,
    errors,
  }
}
