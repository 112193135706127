import { useState, useEffect, useCallback } from "react"
import { navigate } from "gatsby"
import { useQuery, useLazyQuery, useMutation, useApolloClient } from "@apollo/client"

import { useAnalytics } from "./useAnalytics"
import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useCheckoutContext } from "./useCheckout"
import { useShopContext } from "./useShop"
import { useRoutes } from "./useRoutes"
import { useQueries } from "./useQueries"

export const useShopify = () => {
  const client = useApolloClient()
  const { shop } = useShopContext()
  const { checkout } = useCheckoutContext()
  const {
    helpers: { storage },
    graphql: {
      queries: { GET_COLLECTION_PRODUCT_COMPLETE },
    },
  } = useCore()

  const {
    config: {
      settings: { keys },
    },
  } = useApp()

  const {
    queries: { GET_PRODUCTS_BY_HANDLE },
  } = useQueries()

  const edgeNormaliser = object => object?.edges?.map(({ node }) => node) || object || []

  const productNormaliser = product => ({
    ...product,
    images: edgeNormaliser(product?.images)?.map(image => imageNormaliser(image)),
    collections:
      edgeNormaliser(product?.collections)?.map(collection => ({
        ...collection,
        image: imageNormaliser(collection?.image),
      })) || [],
    metafields: product?.metafields?.filter(metafield => metafield),
    variants:
      edgeNormaliser(product?.variants)?.map(variant => ({
        ...variant,
        image: imageNormaliser(variant?.image),
        metafields: edgeNormaliser(variant?.metafields),
      })) || [],
  })

  const collectionNormaliser = collection => ({
    ...collection,
    image: imageNormaliser(collection?.image),
    metafields: edgeNormaliser(collection?.metafields),
    products: edgeNormaliser(collection?.products).map(product => productNormaliser(product)),
  })

  const imageNormaliser = (image, size = null) => ({
    alt: image?.altText || image?.alt || image?.asset?.alt || "",
    src: imageUrl(image?.originalSrc || image?.src || image?.asset?.url || ``, size),
    srcSet: imageSrcSets(image?.originalSrc || image?.src || image?.asset?.url, size),
  })

  const priceNormaliser = (presentmentPrices, field) =>
    Object.assign(
      {},
      ...edgeNormaliser(presentmentPrices)
        ?.filter(item => item[field]?.currencyCode === (checkout?.currencyCode || shop?.paymentSettings?.currencyCode))
        .map(item => ({
          ...item[field],
          local: formatMoney(item[field]?.amount, checkout?.currencyCode || shop?.paymentSettings?.currencyCode),
        }))
    )

  const checkoutNormaliser = checkout => {
    return {
      ...checkout,
      discountApplications: edgeNormaliser(checkout.discountApplications),
      lineItems:
        checkout?.lineItems?.edges.map(({ node }) => ({
          ...node,
          variant: {
            ...node?.variant,
            image: imageNormaliser(node?.variant?.image, 500),
            priceV2: node?.variant?.priceV2,
            compareAtPriceV2: node?.variant?.compareAtPriceV2,
          },
        })) || [],
    }
  }

  const formatMoney = (amount, currency = "AUD") =>
    new Intl.NumberFormat(`en-${shop?.paymentSettings?.countryCode || "AU"}`, {
      style: "currency",
      currency: currency,
    }).format(amount)

  const imageUrl = (src, size = null): any => {
    const dimensions = `${size}x${size}`
    const match = src?.match(/\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i)

    return match && src?.includes(`shopify.com`) && size && size !== `master`
      ? `${src?.split(match[0])[0]}_${dimensions}${match[0]}`.replace(/http(s)?:/, ``)
      : src
  }

  const imageSrcSets = (src, size) =>
    src?.includes(`shopify.com`) &&
    [1, 500, 1000, 1500, 2000]
      .filter(set => !size || (size && size >= set))
      .map(set => `${imageUrl(src, set)} ${set}w`)
      .join(`,`)

  const onSale = (price, compareAtPrice) => compareAtPrice && price && parseInt(compareAtPrice) > parseInt(price)

  const getHandle = item => item?.handle || item?.shopify?.shopifyHandle

  const getCollection = async ({
    firstCollections = 0,
    firstImages = 0,
    metafieldIdentifiers = [],
    firstProducts = 0,
    firstVariants = 0,
    handle,
  }) => {
    const { data } = await client.query({
      query: GET_COLLECTION_PRODUCT_COMPLETE,
      variables: {
        countryCode: storage.get(keys?.country),
        firstCollections,
        firstImages,
        metafieldIdentifiers,
        firstProducts,
        firstVariants,
        handle,
      },
    })

    return collectionNormaliser(data?.collection)
  }

  const getProducts = async ({ firstImages = 0, metafieldIdentifiers = [], firstVariants = 0, handles = [] }) => {
    const filteredHandles = handles?.filter(handle => handle)

    if (!filteredHandles?.length) return []

    const { data } = await client.query({
      query: GET_PRODUCTS_BY_HANDLE(handles),
      variables: {
        countryCode: storage.get(keys?.country),
        metafieldIdentifiers,
        firstImages: firstImages || 99,
        firstVariants: firstVariants || 99,
        firstCollections: 99,
        firstMedia: 99,
        firstMetafields: 99,
      },
    })

    return handles?.map(handle => productNormaliser(data[`product${handle?.replace(/-/g, "")}`]))
  }

  return {
    client,
    useQuery,
    useLazyQuery,
    useMutation,
    onSale,
    imageUrl,
    imageSrcSets,
    formatMoney,
    edgeNormaliser,
    imageNormaliser,
    checkoutNormaliser,
    priceNormaliser,
    productNormaliser,
    collectionNormaliser,
    getHandle,
    getCollection,
    getProducts,
  }
}

export const useShopifyProduct = () => {
  const { activeProduct, setActiveProduct } = useApp()

  const selectProduct = useCallback(
    (product, path) => {
      if (path?.includes("products")) {
        if (!activeProduct) setActiveProduct(product)
      } else {
        if (activeProduct !== false) setActiveProduct(false)
      }
    },
    [activeProduct, setActiveProduct]
  )

  return { activeProduct, selectProduct }
}

export const getVariantBySelectedOptions = (selectedOptions, variants) =>
  variants?.find(
    ({ selectedOptions: variantOptions }) =>
      variantOptions?.filter(
        variantOption => variantOption.value === selectedOptions.find(selectedOption => selectedOption.name === variantOption.name)?.value
      )?.length === selectedOptions?.length
  )

export const useShopifyVariants = ({
  firstAvailable = false,
  useParameter = false,
  loading = false,
  product,
  preSelectedVariantTitle = undefined,
  noDefaultSelectedVariant = false,
}) => {
  const { trackProductView } = useAnalytics()
  const {
    activeVariant,
    activeProduct,
    setActiveVariant,
    config: {
      settings: { params },
    },
  } = useApp()

  const [freeGiftactiveVariant, setFreeGiftActiveVariant] = useState(null)
  const [localActiveVariant, setLocalActiveVariant] = useState(null)

  const {
    helpers: { encodeShopifyId, decodeShopifyId },
  } = useCore()

  const { getUrlParameter, setUrlParameter } = useRoutes()

  const [initialized, setInitialized] = useState(false)

  const { variants } = product

  // get variant id from URL params
  const currentVariantId = getUrlParameter(params?.variant)

  console.log("product: ", product)

  console.log(
    "checker: ",
    useParameter,
    variants,
    variants?.find(({ id }) => id === encodeShopifyId(currentVariantId, "ProductVariant")),
    noDefaultSelectedVariant
  )

  const defaultVariant =
    (useParameter && variants?.find(({ id }) => id === encodeShopifyId(currentVariantId, "ProductVariant"))) ||
    (noDefaultSelectedVariant
      ? undefined
      : variants?.find(variant => variant.title === preSelectedVariantTitle) ||
        (firstAvailable && variants?.find(({ availableForSale }) => availableForSale)) ||
        variants?.[0])

  const [selectedOptions, setOptions] = useState(defaultVariant?.selectedOptions ?? [])

  // handle variant change
  const handleVariant = useCallback(
    option => {
      if (selectedOptions.length > 0) {
        setOptions(selectedOptions.map(selectedOption => (selectedOption.name === option.name ? option : selectedOption)))
      } else {
        setOptions(variants?.[0]?.selectedOptions.map(selectedOption => (selectedOption.name === option.name ? option : selectedOption)))
        setInitialized(true)
      }
    },
    [selectedOptions, setOptions, variants]
  )

  // initialize the default selected Variant
  useEffect(() => {
    if (defaultVariant && !initialized) {
      setActiveVariant(defaultVariant)
      setFreeGiftActiveVariant(defaultVariant)
      setLocalActiveVariant(defaultVariant)
      setInitialized(true)
      setOptions(defaultVariant?.selectedOptions)
    }
  }, [defaultVariant, initialized, setActiveVariant, setFreeGiftActiveVariant])

  // get the specific variant by selectedOptions
  const getVariant = useCallback(
    selectedOptions =>
      variants?.find(
        ({ selectedOptions: variantOptions }) =>
          variantOptions?.filter(
            variantOption => variantOption.value === selectedOptions.find(selectedOption => selectedOption.name === variantOption.name)?.value
          )?.length === selectedOptions?.length
      ),
    [variants]
  )

  // intentionally exclude getVariant from dependencies
  useEffect(() => {
    if (selectedOptions.length > 0) {
      const variant = getVariant(selectedOptions)
      setActiveVariant(variant)
      setLocalActiveVariant(variant)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions, setActiveVariant, setLocalActiveVariant])

  // Navigate to the active variant(add the URL params) if useParameter is enabled
  useEffect(() => {
    if (useParameter && activeVariant?.id && !loading && initialized) {
      if (currentVariantId !== encodeShopifyId(activeVariant?.id, "ProductVariant") && activeVariant?.id !== defaultVariant?.id) {
        navigate(setUrlParameter(params?.variant, decodeShopifyId(activeVariant.id, "ProductVariant")), { replace: true })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVariant?.id, defaultVariant?.id, currentVariantId, activeProduct])

  useEffect(() => {
    trackProductView(activeProduct)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProduct])

  return { localActiveVariant, activeVariant, handleVariant, selectedOptions, freeGiftactiveVariant }
}
