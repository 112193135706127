import React from "react"

import { withSubscribe } from "./withSubscribe"
import { Title, Form, Label, Email, SuccessMessage } from "./SubscribeStyles"
import { StyledInputWrapper, StyledInputIconWrapper, StyledFormErrors } from "../Styled/Form"
import { Icon } from "../Icon/Icon"

export const Subscribe = withSubscribe(
  ({
    email,
    handleChange,
    handleSubmit,
    loading,
    errors,
    title,
    placeholder,
    success,
    successMessage,
    focused,
    handleFocus,
    handleBlur,
  }): JSX.Element => (
    <Form onSubmit={handleSubmit} autoComplete="off">
      <Title htmlFor={"email"}>{title}</Title>
      <StyledInputWrapper>
        {/*<label className="group animate-focus" htmlFor={"email"}>        */}
        {/*  <Email         */}
        {/*  id="email" name="email" type="email" value={email}*/}
        {/*  onBlur={handleBlur}*/}
        {/*  onFocus={handleFocus} onChange={handleChange} required />*/}
        {/*  <Label focused={focused}>{placeholder}</Label>*/}
        {/*</label>*/}

        <Email id="email" name="email" type="email" value={email} onChange={handleChange} placeholder={placeholder} required />
        <StyledInputIconWrapper type={`submit`} disabled={loading}>
          <Icon name={"arrow"} title={"Subscribe submit"} width={21} height={21} />
        </StyledInputIconWrapper>
      </StyledInputWrapper>
      {success && <SuccessMessage>{successMessage}</SuccessMessage>}
      {errors && <StyledFormErrors>{errors}</StyledFormErrors>}
    </Form>
  )
)
