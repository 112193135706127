import tw, { styled } from "twin.macro"

import { StyledInput } from "../Styled/Form"
import { Body, SubTitle } from "../Styled/Text"

export const Form = tw.form`
  flex flex-col mb-2-4 md:mb-4 mt-2 md:mt-4
`
export const Title = tw(SubTitle)`mb-0-4 md:mb-0-8`

export const Label = styled.span`
  ${tw`transform group-focus:-mt-1 text-beige-light font-medium absolute top-0 text-12 md:text-13 normal-case focus:hidden`}
  ${({ focused }) => (focused ? tw`mt-1` : tw`mt-3`)}
`

export const Email = styled(StyledInput)`
  ${tw`text-18 py-1-8 md:py-2-4 bg-transparent font-medium border-b-2 md:border-b-3 border-beige-light mt-1 placeholder-white`}
`

export const SuccessMessage = styled(Body)`
  ${tw`mt-0-8`}
`
