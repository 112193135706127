const configDefault = {
  app: {
    title: "Rossi Boots",
    description:
      "Australian owned since 1910, Rossi Boots go the distance. Perfect for hard work and weekends, our range caters for men and women from many walks of life.",
    url: "https://rossiboots.com.au/",
    logo: "static/images/logo.svg",
    themeColor: "#000000",
    themeBackground: "#FFFF04",
    themeDisplay: "minimal-ui",
    themeIcon: "static/images/icon.png",
  },
  theme: {
    logo: "static/images/logo.svg",
    themeColor: "#ffffff",
    themeBackground: "#FFFF04",
    themeDisplay: "minimal-ui",
    themeIcon: "static/images/icon.png",
    color: "#ffffff",
    background: "#FFFF04",
    display: "minimal-ui",
    icon: "static/images/icon.png",
  },
  stores: {
    "rossi-boots": {
      siteLocation: "AU",
      siteCountries: ["AU", "US"],
      shopName: "rossi-boots",
      shopDomain: "checkout.rossiboots.com.au",
      accessToken: "02e3113389027b4a888a0c8d57f65ccc",
      sanityProjectId: "fnvwa3hq",
      sanityDataset: "production",
      searchDomain: "rossi-boots.myshopify.com",
      searchIndex: "rossi-boots-search-v2",
    },
    "rossi-boots-stage": {
      siteLocation: "AU",
      siteCountries: ["AU", "US"],
      shopName: "rossi-boots-stage",
      shopDomain: "rossi-boots-stage.myshopify.com",
      accessToken: "26bad92448f4d98cad9758066f1015ed",
      sanityProjectId: "fnvwa3hq",
      sanityDataset: "staging",
      searchDomain: "rossi-boots-stage.myshopify.com",
      searchIndex: "rossi-boots-stage-search-v2",
    },
  },
  get store() {
    return this.stores[process.env.GATSBY_SHOPIFY_DEFAULT_SHOP]
  },
  services: {
    shopify: {
      apiVersion: "2023-04",
      defaultShopName: process.env.GATSBY_SHOPIFY_DEFAULT_SHOP,
    },
    sanity: {
      projectId: "fnvwa3hq",
      token:
        "skPl8ZsA9eg2oCAgtVJZHUdOEkDD5zCwcKq8lugoaunEcepn8fcNHhnbw8JUeTo2Cw0MgtEe1530pMFGVBSWUdXUUkzQdk1VZZbC8mJmGraDQlhMJsUCFrrrdvz5Y0R7l2XyD2T9B18OffiLntd0idNZk8owviHKmhIxsaWC9LtSD5FfwYwY",
      dataset: process.env.GATSBY_SANITY_DEFAULT_DATASET,
    },
    functions: {
      ...("production" === process.env.GATSBY_ACTIVE_ENV && {
        endpoint: "https://api-rossi-boots-website.web.app",
      }),
      ...("development" === process.env.GATSBY_ACTIVE_ENV && {
        endpoint: "https://api-rossi-boots-website-stage.web.app",
      }),
    },
    firebase: {
      ...("production" === process.env.GATSBY_ACTIVE_ENV && {
        region: "us-central1",
        apiKey: "AIzaSyDBERKauzcMlvBhVrVmNXYf-5DOPJ-aR9g",
        authDomain: "rossi-boots-website.firebaseapp.com",
        databaseURL: "https://rossi-boots-website.firebaseio.com",
        projectId: "rossi-boots-website",
        storageBucket: "rossi-boots-website.appspot.com",
        messagingSenderId: "761546609149",
        appId: "1:761546609149:web:cabe965e60d9a3741a4994",
      }),
      ...("development" === process.env.GATSBY_ACTIVE_ENV && {
        region: "us-central1",
        apiKey: "AIzaSyCmcxrvrmrnwzpQlG19i9DSi9aqP75x_fA",
        authDomain: "rossi-boots-website-stage.firebaseapp.com",
        databaseURL: "https://rossi-boots-website-stage.firebaseio.com",
        projectId: "rossi-boots-website-stage",
        storageBucket: "rossi-boots-website-stage.appspot.com",
        messagingSenderId: "352109085689",
        appId: "1:352109085689:web:c47bf730cc8e9fa98ff328",
      }),
    },
    location: {
      serviceUrl: "https://ipapi.co/json/",
      countryFieldKey: "country",
      forceRegion: true,
    },
    reactify: {
      ...("production" === process.env.GATSBY_ACTIVE_ENV && {
        searchUrl: "https://api.search.reactify.app/",
        searchConfig: "https://config.search.reactify.app?shop=rossi-boots.myshopify.com",
        searchFieldKey: "q",
      }),
      ...("development" === process.env.GATSBY_ACTIVE_ENV && {
        searchUrl: "https://api.search.reactify.app/",
        searchConfig: "https://config.search.reactify.app?shop=rossi-boots-stage.myshopify.com",
        searchFieldKey: "q",
      }),
    },
    googleMaps: {
      apiKey: "AIzaSyBtrCFVYJeI68eFKw4hBKY2E3uWGIiEhB8",
    },
    instagram: {
      url: "https://www.instagram.com/p",
    },
    giftcard: {
      ...("production" === process.env.GATSBY_ACTIVE_ENV && {
        // balance: "getGiftcard",
        balance: "https://australia-southeast1-rossi-boots-dotapparel21-au.cloudfunctions.net/api-checkGiftcardBalance",
      }),
      ...("development" === process.env.GATSBY_ACTIVE_ENV && {
        // balance: "getGiftcard",
        balance: "https://australia-southeast1-rossi-boots-dotapparel21-stage.cloudfunctions.net/api-checkGiftcardBalance",
      }),
    },
  },
  queries: {
    products: {
      query: `allSanityProduct(filter: {shopify: {shopifyPublished: {eq: true}, shopifyDeleted: {ne: true}}}) { edges { node { tags, shopify { handle: shopifyHandle, shopifyRaw, deleted: shopifyDeleted }   } } }`,
      template: "templates/product.tsx",
      hidden: "hidden",
      devPageLimit: 100,
    },
    collections: {
      query: `allSanityCollection(filter: {shopify: {shopifyDeleted: {ne: true}}}) { edges { node { shopify {  handle: shopifyHandle, deleted: shopifyDeleted } } } }`,
      template: "templates/collection.tsx",
      hidden: "hidden",
      devPageLimit: 100,
    },
    articles: {
      query: `allSanityArticle { edges { node { _id handle: slug { current } } } }`,
      template: "templates/article.tsx",
      devPageLimit: 100,
    },
    stores: {
      query: `allSanityLocation { edges { node { _id handle: slug { current } } } }`,
      template: "templates/store.tsx",
      devPageLimit: 100,
    },
    flexible: {
      query: `allSanityPageFlexible { edges { node { _id handle: slug { current } } } }`,
      template: "templates/flexible.tsx",
    },
    generic: {
      query: `allSanityPageGeneric { edges { node { _id handle: slug { current } } } }`,
      template: "templates/generic.tsx",
    },
    redirects: {
      query: `allSanityRedirect { edges { node { source type destination } } }`,
      devPageLimit: 0,
    },
    robots: {
      query: `allSanitySettingRobots { nodes { content { code } } }`,
    },
  },
  settings: {
    keys: {
      shopify: "headless:shopify",
      country: "headless:country",
      location: "headless:location",
      checkout: "headless:checkout",
      discounts: "headless:discounts",
      customer: "headless:customer",
      wishlist: "headless:wishlist",
      announcement: "headless:announcement",
      cookies: "headless:cookies",
      maintenance: "headless:maintenance",
      newsletter: "headless:newsletter",
      popups: "headless:popups",
      password: "headless:password",
      preOrderKey: "Pre-order",
      preOrderValue: "Yes",
      collection_scroll_position: "headless:collection_scroll_position",
    },
    params: {
      continue: "return",
      customer: "key",
      giftcard: "redeem",
      variant: "variant",
      wishlist: "id",
      articlesFilter: "filter",
      articlesSortBy: "sortby",
      articlesPage: "page",
      checkoutRef: "ref",
    },
    routes: {
      HOMEPAGE: "/",
      FLEXIBLE: "",
      GENERIC: "",
      PAGE: "",
      CART: "/cart",
      CONTACT: "/contact",
      SEARCH: "/search",
      PASSWORD: "/password",
      SAVED: "/wishlist",
      LOGIN: "/account/login",
      REGISTER: "/account/register",
      FORGOT: "/account/forgot",
      RESET: "/account/reset",
      ACTIVATE: "/account/activate",
      RETURNS: "/account/returns",
      DASHBOARD: "/account",
      ORDERS: "/account/orders",
      ADDRESSES: "/account/addresses",
      PREFERENCES: "/account/preferences",
      WISHLIST: "/wishlist",
      PRODUCT: "/products",
      COLLECTION: "/collections",
      ARTICLE: "/articles",
      STORES: "/stores",
      STORE: "/stockist-locator",
      GIFTCARD: "/giftcard",
    },
    tags: {
      badge: "badge",
      message: "message",
      siblings: "product_line",
      swatchColor: "swatch_colour",
      feature: "feature",
      sizeGuide: "size_chart",
    },
    constraints: {
      SIZE: "Size",
      COLOUR: "Colour",
      SPEC: "fullProductDescription",
      CARE: "careInstructions",
      productMetafields: [
        { key: "reactify", namespace: "pre-order" },
        { key: "careInstructions", namespace: "marketing" },
        { key: "fit", namespace: "marketing" },
        { key: "fullProductDescription", namespace: "marketing" },
        { key: "hide", namespace: "da-restock" },
      ],
    },
    giftCardConstraints: {
      DIGITAL: "digital",
      PHYSICAL: "physical",
      CUSTOMAMOUNT: "customAmount",
      SENDER: "From",
      RECEIVER: "To",
      RECEIVER_EMAIL: "Email",
      MESSAGE: "Message",
    },
    defaultRedirects: [
      { source: "/collection", destination: "/collections", type: 301 },
      { source: "/checkout", destination: "/cart", type: 301 },
      { source: "/home", destination: "/", type: 301 },
    ],
    clientPaths: ["/account/reset/*", "/account/activate/*", "/account/orders/*"],
    sitemapExclusions: [
      "/offline-plugin-app-shell-fallback",
      "/dev-404-page",
      "/404",
      "/404.html",
      "/password",
      "/preview",
      "/account",
      "/account/login",
      "/account/forgot",
      "/account/activate",
      "/account/reset",
      "/account/orders",
      "/account/addresses",
      "/account/preferences",
      "/account/wishlist",
      "/wishlist",
      "/search",
      "/cart",
      "/cart/login",
    ],
    socialMedia: {
      sharing: {
        facebook: "https://www.facebook.com/sharer/sharer.php?u=",
        pinterest: "http://pinterest.com/pin/create/button/?url=",
      },
    },
  },
}

module.exports = configDefault
