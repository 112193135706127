import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useFunctions } from "../../hooks/useFunctions"

export const withSubscribe = Component => ({ name = "Subscribe" }: any) => {
  const { customerSubscribe, loading, errors } = useFunctions()
  const [focused, setFocus] = useState(false)
  const { global } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_GLOBAL_SUBSCRIBE {
      global: sanityTemplateGlobal {
        additionalFooterSubscribeTitle
        additionalSubscribeInputPlaceholder
      }
    }
  `)

  const { additionalFooterSubscribeTitle, additionalSubscribeInputPlaceholder, additionalSubscribeSuccessMessage } = global || {}

  const [email, setEmail] = useState("")
  const [success, setSuccess] = useState(false)

  const handleSubmit = async event => {
    event.preventDefault()
    const { customer } = await customerSubscribe(email)
    if (customer?.id) {
      setSuccess(true)
      setEmail("")
    }
  }

  const handleChange = ({ target: { value } }) => {
    setEmail(value)
  }

  const handleFocus = () => {
    setFocus(true)
  }

  const handleBlur = ({ target: { value } }) => {
    setFocus(!!value)
  }

  Component.displayName = name
  return (
    <Component
      email={email}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      handleFocus={handleFocus}
      handleBlur={handleBlur}
      loading={loading}
      errors={errors}
      title={additionalFooterSubscribeTitle}
      placeholder={additionalSubscribeInputPlaceholder}
      success={success}
      successMessage={additionalSubscribeSuccessMessage}
      focused={focused}
    />
  )
}
